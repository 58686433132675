article.post {

  header {
    .image-header {
      background: url(/assets/img/body-bg.jpg)
                  no-repeat
                  center
                  center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      width: 100%;
      height: 300px;
      margin: 20px 0;
    }

    .post-title {
      font-family: "Roboto Slab";
      font-weight: 400;
      font-size: 3em;
    }

    .tags {
      span {
        background: #F2F2F2;
        padding: 2px 8px;
        margin: 2px;
        border-radius: 2px;
        font-size: .8em;
      }
    }
  }

  .post-content {
    margin-top: 50px;
    padding: 0 5%;
  }
}