.home {
  margin-top: -50px;

  header {

    h1:first-child {
      font-family: "Roboto Slab", Oswald;
      font-size: 110px;
      margin: 0;

      @include media-query($on-palm) {
        font-size: 100px;
      }
    }

    ol {
      padding: 0 100px 0 20px;
      font-size: 0.8em;
      text-align: justify;
    }
  }

  hr {
    color: $brand-color;
    border-color: $brand-color;
    margin: 50px 0;
  }

  //---------------------------------------------------------------------------

  .post-list {
    margin: 0;
    padding: 0 5%;

    li {
      display: flex;
      flex-direction: row;
      margin: 50px 0;

      @include media-query($on-palm) {
        flex-direction: column;
      }

      .image-header {
        background: url(/assets/img/body-bg.jpg)
                    no-repeat
                    center
                    center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
        background-size: cover;
        width: 30%;
        margin-right: 30px;

        @include media-query($on-palm) {
          width: 100%;
          height: 200px;
          margin-bottom: 20px;
        }
      }

      .tags {
        margin: 0 -2px;

        span {
          background: #F2F2F2;
          padding: 2px 8px;
          margin: 2px;
          border-radius: 2px;
          font-size: .6em;
        }
      }

      .content {
        flex: 1;
        font-size: 0.9em;

        header {
          margin-bottom: 20px;

          .title {
            margin: 0;
            font-family: "Roboto Slab";
            color: $brand-color;
          }

          .date {
            font-size: 0.8em;
          }
        }
      }
    }
  }
}