body {
  display: flex;
  flex-direction: row;
  max-height: 100vh;

  // Photo by Émile Perron on Unsplash
  // background: url(/assets/img/body-bg.jpg)
  //             no-repeat
  //             center
  //             center
  //             fixed;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
  background-color: #F2F2F2;
  padding-left: 300px;

  @include media-query($on-palm) {
    padding-left: 0;
    flex-direction: column;
  }

  header.site-sidebar {
    flex: 1;
    max-width: 300px;
    background: linear-gradient(117deg, #252525 50%, #111);
    color: white;
    text-align: center;
    height: 100vh;
    overflow: hidden;
    box-shadow: 0 -20px 40px 0 rgba(17, 17, 17, 0.72);
    position: fixed;
    left: 0;
    z-index: 9999;

    @include media-query($on-palm) {
      position: relative;
      top: 0;
      max-width: 100vw;
      width: 100vw;
      max-height: 300px;
      min-height: 300px;
      padding-bottom: 30px;

      h3 {
        margin: 0;
      }
    }

    .wrapper {
      height: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    .profile-pic {
      background: url(/assets/img/profile.jpg);
      background-size: cover;
      min-width: 150px;
      max-width: 150px;
      min-height: 150px;
      max-height: 150px;
      border-radius: 50%;
      margin: 30px auto;
      border: 2px solid $brand-color;

      @include media-query($on-palm) {
        min-width: 100px;
        max-width: 100px;
        min-height: 100px;
        max-height: 100px;
        margin: 30px auto 10px;
      }
    }

    .location {
      @include media-query($on-palm) {
        display: none;
      }
    }

    hr {
      border-color: $brand-color;
    }

    //-------------------------------------------------------------------------

    .social .social-media-list {
      list-style: none;
      display: flex;
      flex-direction: row;
      margin: 20px 0;
      justify-content: center;

      > li {
        padding: 0;
        margin: 0 5px;
      }
    }

    //-------------------------------------------------------------------------

    nav {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin: 30px 0;
      padding: 0 10px;
      flex: 1;
      font-size: 1.2em;

      @include media-query($on-palm) {
        flex-direction: row;
        justify-content: space-around;
      }

      a {
        font-style: none;
        height: 40px;
        color: white;
      }
    }
  }

  main {
    flex: 1;

    .wrapper {
      background: white;
      border: solid 1px #E6E6E6;
      box-shadow: 0 0 20px 0 #E6E6E6;
      min-height: 100vh;
      margin-top: 50px;
      padding: 80px 50px;
      border-radius: 10px;

      @include media-query($on-palm) {
        margin-top: -10px;
      }
    }
  }
}